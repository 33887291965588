<template>
  <main>
    <div
      v-if="offerings && offerings.length > 0"
      class="container page-content"
    >
      <div class="text-end">
        <router-link :to="{ name: 'CreateOffering' }" class="btn btn-primary"
          >Create Offering</router-link
        >
      </div>
      <h3 class="mt-2">Offerings</h3>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div v-for="offering in offerings" :key="offering.id" class="col">
              <OfferingSingle :offering="offering" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
    <div class="text-center" v-if="offerings && offerings.length == 0">
      <h4>Welcome to Round Here.</h4>
      <p>
        This is where your offerings will appear after you create them. If you
        are ready to go, let&apos;s get started.
      </p>
      <router-link
        :to="{ name: 'CreateOffering' }"
        class="btn btn-lg btn-primary"
        >Create Offering</router-link
      >
    </div>
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import TagCloud from "@/components/tags/TagCloud.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";

export default {
  components: { OfferingSingle, Footer, TagCloud },
  setup() {
    const { user } = getUser();
    const { error, documents: offerings } = getCollection(
      "offerings",
      [["issuerId", "==", user.value.uid]],
      ["name", "asc"]
    );

    return { error, offerings };
  }
};
</script>

<style scoped>
</style>